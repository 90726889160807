import { useState } from "react";

const MatchStatistics = ({ matchStats }) => {
  const [view, setView] = useState("overall");

  // Ovdje staviti shared komponentu
  if (!matchStats || matchStats.length === 0) {
    return <p>No statistics available</p>;
  }

  const statTypeNames = {
    possessionPercentage: "Posjed lopte",
    expectedGoals: "Očekivani golovi (xG)",
    totalScoringAtt: "Ukupno udaraca",
    bigChanceCreated: "Velike šanse",
    bigChanceMissed: "Propuštene velike šanse",
    accuratePass: "Točna dodavanja",
    fkFoulLost: "Prekršaji",
    totalOffside: "Zaleđa",
    cornerTaken: "Udarci iz kuta",
    totalPass: "Dodavanja",
    openPlayPass: "Dodavanja iz igre",
    successfulOpenPlayPass: "Točna dodavanja iz igre",
    fwdPass: "Dodavanje prema naprijed",
    backwardPass: "Dodavanja prema nazad",
    offtargetAttAssist: "Udarci izvan okvira",
    ontargetScoringAtt: "Udarci unutar okvira",
    blockedScoringAtt: "Blokirani udarci",
    hitWoodwork: "Pogodak u okvir gola",
    attemptsIbox: "Udarci unutar kaz. prostora",
    attemptsObox: "Udarci van kaz. prostora",
    attOpenplay: "Udarci iz igre",
    attSetpiece: "Udarci iz prekida",
    attCorner: "Udarci iz kornera",
    expectedGoalsOpenplay: "Očekivani golovi iz igre",
    expectedGoalsSetplay: "Očekivani golovi iz prekida",
    expectedGoalsontarget: "Očekivani golovi u okvir (xGOT)",
    expectedAssists: "Očekivane asistencije",
    accurateBackZonePass: "Točna dodavanja na vlastitoj polovici",
    accurateFwdZonePass: "Točna dodavanja na polovici protivnika",
    totalFwdZonePass: "Dodavanja na protivničkoj polovici",
    totalBackZonePass: "Dodavanja na vlastitoj polovici",
    totalFinalThirdPasses: "Dodavanja na zadnjoj trećini",
    successfulFinalThirdPasses: "Točna dodavanja zadnjoj trećini",
    totalLongBalls: "Duge lopte",
    accurateLongBalls: "Točne duge lopte",
    totalCross: "Centaršutevi",
    accurateCross: "Točni centaršutevi",
    totalThrows: "Ubacivanje sa strane",
    wonTackle: "Oduzete lopte",
    interception: "Presjecanja",
    outfielderBlock: "Blokirani udarci",
    sixYardBlock: "Blokirani udarci u 5m",
    totalClearance: "Otklonjenja opasnost",
    saves: "Obrane vratara",
    duelWon: "Osvojeni dueli",
    duelLost: "Izgubljeni dueli",
    aerialWon: "Osvojeni zračni dueli",
    aerialLost: "Izgubljeni zračni dueli",
    totalContest: "Driblinzi",
    wonContest: "Uspješni driblinzi",
    totalYellowCard: "Žuti kartoni",
    totalRedCard: "Crveni kartoni",
  };

  const statisticGroups = {
    "Glavna statistika": [
      "expectedGoals",
      "possessionPercentage",
      "totalScoringAtt",
      "bigChanceCreated",
      "bigChanceMissed",
      "accuratePass",
      "fkFoulLost",
      "totalOffside",
      "cornerTaken",
      "totalYellowCard",
    ],
    Udarci: [
      "totalScoringAtt",
      "ontargetScoringAtt",
      "offtargetAttAssist",
      "blockedScoringAtt",
      "hitWoodwork",
      "attemptsIbox",
      "attemptsObox",
      "attOpenplay",
      "attSetpiece",
      "attCorner",
    ],
    "Očekivani golovi (xG)": [
      "expectedGoals",
      "expectedGoalsOpenplay",
      "expectedGoalsSetplay",
      "expectedGoalsontarget",
      "expectedAssists",
    ],
    Dodavanja: [
      "totalPass",
      "accuratePass",
      "openPlayPass",
      "successfulOpenPlayPass",
      "fwdPass",
      "backwardPass",
      "totalBackZonePass",
      "accurateBackZonePass",
      "totalFwdZonePass",
      "accurateFwdZonePass",
      "totalFinalThirdPasses",
      "successfulFinalThirdPasses",
      "totalLongBalls",
      "accurateLongBalls",
      "totalCross",
      "accurateCross",
      "totalThrows",
    ],
    Obrana: [
      "wonTackle",
      "interception",
      "outfielderBlock",
      "sixYardBlock",
      "totalClearance",
      "saves",
    ],
    Dueli: [
      "duelWon",
      "duelLost",
      "aerialWon",
      "aerialLost",
      "totalContest",
      "wonContest",
    ],
    Kazne: ["totalYellowCard", "totalRedCard"],
  };

  const getFilteredStats = () => {
    if (view === "1st") {
      return matchStats.filter(
        (stat) => stat.fh !== undefined && stat.fh !== ""
      );
    }
    if (view === "2nd") {
      return matchStats.filter(
        (stat) => stat.sh !== undefined && stat.sh !== ""
      );
    }
    return matchStats;
  };
  const filteredStats = getFilteredStats();

  const homeStats = filteredStats
    .filter((stat) => stat.isHome === 1)
    .sort((a, b) => a.id - b.id)
    .reduce((acc, stat) => {
      acc[stat.type] = stat;
      return acc;
    }, {});

  const awayStats = filteredStats
    .filter((stat) => stat.isHome === 0)
    .sort((a, b) => a.id - b.id)
    .reduce((acc, stat) => {
      acc[stat.type] = stat;
      return acc;
    }, {});
    
  const sumStats = (type, stat1, stat2) => {
    const val1 = parseFloat(stat1) || 0;
    const val2 = parseFloat(stat2) || 0;
    if (type === "possessionPercentage") {
      let avg;
      if (val2 === 0) {
        avg = (val1 + val2).toFixed(2);
      } else {
        avg = ((val1 + val2) / 2).toFixed(2);
      }
      return isNaN(avg) ? "0" : avg;
    }
    const sum = (val1 + val2).toFixed(2);
    return isNaN(sum) ? "0" : sum;
  };

  const roundStat = (type, value) => {
    const parsedValue = parseFloat(value);
    if (isNaN(parsedValue)) {
      return "0"; // Default to 0 if the value is not a number
    }
    if (type === "possessionPercentage") {
      return Math.round(parsedValue) + "%"; // Round to the nearest integer and add %
    } else if (type.startsWith("expectedGoals") || type === "expectedAssists") {
      return parsedValue.toFixed(2);
    } else {
      return Math.round(parsedValue).toString();
    }
  };

  const renderStatRow = (type) => {
    const homeStat = homeStats[type] || {};
    const awayStat = awayStats[type] || {};

    let homeValueRaw;
    let awayValueRaw;

    if (view === "1st") {
      homeValueRaw = homeStat.fh;
      awayValueRaw = awayStat.fh;
    } else if (view === "2nd") {
      homeValueRaw = homeStat.sh;
      awayValueRaw = awayStat.sh;
    } else {
      // Overall view
      if (type === "possessionPercentage") {
        // Calculate the average for possession percentage
        homeValueRaw = sumStats(type, homeStat.fh, homeStat.sh);
        awayValueRaw = sumStats(type, awayStat.fh, awayStat.sh);
      } else {
        // Sum the first and second half for other stats
        homeValueRaw = sumStats(type, homeStat.fh, homeStat.sh);
        awayValueRaw = sumStats(type, awayStat.fh, awayStat.sh);
      }
    }

    const homeValue = roundStat(type, homeValueRaw);
    const awayValue = roundStat(type, awayValueRaw);

    const homeStyle =
      parseFloat(homeValue) > parseFloat(awayValue)
        ? "bg-greenStats text-white text-scoreText rounded-md flex items-center justify-center font-normal h-[24px]"
        : "font-normal text-black text-scoreText items-center justify-center";
    const guestStyle =
      parseFloat(awayValue) > parseFloat(homeValue)
        ? "bg-greenStats text-white text-scoreText rounded-md flex items-center justify-center font-normal h-[24px]"
        : "font-normal text-black text-scoreText items-center justify-center";

    return (
      <tr key={type} className="text-center">
        <td className="w-[50px] h-[26px] px-2 py-2 text-scoreText">
          <div className={homeStyle}>{homeValue}</div>
        </td>
        <td className="px-2 text-scoreText font-fw500">
          {statTypeNames[type]}
        </td>
        <td className="w-[28px] h-[26px] px-2 py- text-scoreText">
          <div className={guestStyle}>{awayValue}</div>
        </td>
      </tr>
    );
  };

  const renderStatGroup = (groupName, statTypes) => (
    <tbody key={groupName}>
      <tr>
        <th colSpan="3" className="text-left">
          <div className="flex bg-cardContainerGrey p-2 font-fw500">
            {groupName}
          </div>
        </th>
      </tr>
      {statTypes.map((type) => renderStatRow(type))}
    </tbody>
  );

  return (
    <div>
      <div className="flex justify-center m-1">
        <button
          onClick={() => setView("overall")}
          className={`px-2 mx-1 rounded-full focus:outline-none ${
            view === "overall"
              ? "bg-bgMain text-white"
              : "bg-white border border-bgMain text-bgMain"
          }`}
        >
          Sve
        </button>
        <button
          onClick={() => setView("1st")}
          className={`px-2 mx-1 rounded-full focus:outline-none ${
            view === "1st"
              ? "bg-bgMain text-white"
              : "bg-white border border-bgMain text-bgMain"
          }`}
        >
          1.poluvrijeme
        </button>
        <button
          onClick={() => setView("2nd")}
          className={`px-2 mx-1 rounded-full focus:outline-none ${
            view === "2nd"
              ? "bg-bgMain text-white"
              : "bg-white border border-bgMain text-bgMain"
          }`}
        >
          2.poluvrijeme
        </button>
      </div>
      <table className="table-auto w-full my-4">
        {Object.entries(statisticGroups).map(([groupName, statTypes]) =>
          renderStatGroup(groupName, statTypes)
        )}
      </table>
    </div>
  );
};

export default MatchStatistics;
