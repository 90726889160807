import React, { useState, useEffect } from 'react';
import MatchDetailsContent from '../components/match/MatchDetailsContent';
import { useParams } from 'react-router-dom';
import {
  fetchMatchDetails,
  getLastFiveH2HMatches,
  getTeamLastFive,
  fetchMatchStats,
  fetchMatchEvents,
} from '../services/match-service';
import {
  fetchMatchFormations,
  getInjuredSuspended,
} from '../services/lineups-service';
import Loader from '../shared/Loader';
import MatchStatistics from '../components/match/MatchStatistics';
import MatchH2H from '../components/match/MatchH2H';
import MatchFormationTab from '../components/formation/MatchFormationContent';
import { Tabs } from '../shared/Tabs';

export default function MatchDetails() {
  const [activeTab, setActiveTab] = useState('details');
  const [matchDetails, setMatchDetails] = useState(null);
  const [matchStats, setMatchStats] = useState(null);
  const [matchEvents, setMatchEvents] = useState(null);
  const [matchFormations, setMatchFormations] = useState(null);
  const { matchId } = useParams();
  const [homeTeamLastFive, setHomeTeamLastFive] = useState([]);
  const [guestTeamLastFive, setGuestTeamLastFive] = useState([]);
  const [lastFiveH2H, setLastFiveH2H] = useState([]);
  const [loading, setLoading] = useState(true);
  const [betRadarMappings, setBetRadarMappings] = useState({});
  const [injuredSuspendedHome, setInjuredSuspendedHome] = useState([]);
  const [injuredSuspendedGuest, setInjuredSuspendedGuest] = useState([]);

  // Fetch the BetRadar mappings from the public/config directory
  useEffect(() => {
    const fetchMappings = async () => {
      try {
        const response = await fetch('/config/betRadarIdsMapping.json');
        const data = await response.json();
        setBetRadarMappings(data);
      } catch (error) {
        console.error('Error fetching BetRadar mappings:', error);
      }
    };
    fetchMappings();
  }, []);

  useEffect(() => {
    if (matchId && Object.keys(betRadarMappings).length > 0) {
      setActiveTab('details');

      // Check if matchId exists in BetRadar ID mapping
      const mappedMatchId = betRadarMappings[matchId] || matchId;

      setLoading(true);
      fetchMatchData(mappedMatchId);
    }
  }, [matchId, betRadarMappings]);

  const fetchMatchData = async (matchId) => {
    try {
      const data = await fetchMatchDetails(matchId);
      const stats = await fetchMatchStats(matchId);
      const events = await fetchMatchEvents(matchId);
      const formations = await fetchMatchFormations(matchId);
      setMatchDetails(data || {});
      setMatchStats(stats || {});
      setMatchEvents(events || []);
      setMatchFormations(formations || []);

      
      if (data && data.matches && data.matches.length > 0) {
        // Fetch injured/suspended players for both home and guest teams
        const homeTeamId = data.matches[0]?.homeTeam.sourceId;
        const guestTeamId = data.matches[0]?.guestTeam.sourceId;

        if (homeTeamId && guestTeamId) {
          const homeInjuredSuspended = await getInjuredSuspended(homeTeamId);
          const guestInjuredSuspended = await getInjuredSuspended(guestTeamId);

          setInjuredSuspendedHome(homeInjuredSuspended || []);
          setInjuredSuspendedGuest(guestInjuredSuspended || []);
        }
      }

      if (data && data.matches && data.matches.length > 0) {
        // Fetch other data using id
        const homeTeamId = data.matches[0]?.homeTeam.id;
        const guestTeamId = data.matches[0]?.guestTeam.id;

        if (homeTeamId && guestTeamId) {
          const homeTeamLastFive = await getTeamLastFive(homeTeamId);
          const guestTeamLastFive = await getTeamLastFive(guestTeamId);
          const last5H2H = await getLastFiveH2HMatches(homeTeamId, guestTeamId);

          setHomeTeamLastFive(homeTeamLastFive || []);
          setGuestTeamLastFive(guestTeamLastFive || []);
          setLastFiveH2H(last5H2H || []);
        }
      }
    } catch (error) {
      console.error('Error fetching match data:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const tabs = [
    {
      label: 'Detalji',
      isActive: activeTab === 'details',
      onClick: () => handleTabClick('details'),
    },
    {
      label: 'Postava',
      isActive: activeTab === 'formation',
      onClick: () => handleTabClick('formation'),
      injuredSuspendedHome: injuredSuspendedHome,
      injuredSuspendedGuest: injuredSuspendedGuest,
    },
    {
      label: 'H2H',
      isActive: activeTab === 'H2H',
      onClick: () => handleTabClick('H2H'),
    },
    {
      label: 'Statistika',
      isActive: activeTab === 'statistics',
      onClick: () => handleTabClick('statistics'),
    },
  ];

  return (
    <div>
      <div className="p-3 bg-white ">
        <Tabs tabs={tabs} />
      </div>
      {loading ? (
        <Loader />
      ) : (
        <>
          {activeTab === 'details' && (
            <MatchDetailsContent
              matchDetails={matchDetails}
              matchStats={matchStats}
              matchEvents={matchEvents}
            />
          )}
          {activeTab === 'formation' && (
            <MatchFormationTab
              matchDetails={matchDetails}
              matchFormation={matchFormations}
              matchEvents={matchEvents}
              injuredSuspendedGuest={injuredSuspendedGuest}
              injuredSuspendedHome={injuredSuspendedHome}
            />
          )}
          {activeTab === 'H2H' && (
            <MatchH2H
              matchDetails={matchDetails}
              homeTeamLastFive={homeTeamLastFive}
              matchId={matchId}
              guestTeamLastFive={guestTeamLastFive}
              lastFiveH2HMatches={lastFiveH2H}
            />
          )}
          {activeTab === 'statistics' && (
            <MatchStatistics matchStats={matchStats} />
          )}
        </>
      )}
    </div>
  );
}
