import HeadingTitle from "../layout/HeadingTitle";

const MarketStats = ({ topBettingMarkets }) => {
  const typeMapping = {
    zbrojGolova: "Zbroj golova 2.5",
    obaDajuGol: "Oba daju gol",
    obaDajuGol3Plus: "Oba daju gol i 3+",
    ekipaDaNeGol: "Ekipa daje gol",
    golObaPoluvremena: "Gol u oba poluvremena",
  };

  const statistics = [
    { type: "Zbroj golova 2.5", value1: 0, value2: 0 },
    { type: "Oba daju gol", value1: 0, value2: 0 },
    { type: "Oba daju gol i 3+", value1: 0, value2: 0 },
    { type: "Ekipa daje gol", value1: 0, value2: 0 },
    { type: "Gol u oba poluvremena", value1: 0, value2: 0 },
  ];

  if (topBettingMarkets.length > 0) {
    topBettingMarkets.forEach((market) => {
      const mappedType = typeMapping[market.type];
      const stat = statistics.find((s) => s.type === mappedType);
      if (stat) {
        stat.value1 = market.value;
        stat.value2 = market.numberOfMatches;
      }
    });
  }

  return (
    <div>
      <HeadingTitle title={"Prolaznost ponude na turniru"} />
      <div className="overflow-hidden">
        <table className="w-full">
          <tbody>
            {statistics.map((stat, index) => {
              const percentage =
                stat.value2 === 0 ? 0 : (stat.value1 / stat.value2) * 100;

              return (
                <tr
                  key={index}
                  className="flex items-center w-full p-2 text-scoreText font-fw500 leading-4"
                >
                  <td className="w-1/2 my-1 text-textSecondaryLight">
                    {stat.type}
                  </td>
                  <td className="w-1/2 flex items-center space-x-2">
                    <div className="flex-grow bg-gray-200 h-2 rounded-full overflow-hidden">
                      <div
                        className="bg-gray-400 h-full"
                        style={{
                          width: `${percentage}%`,
                        }}
                      ></div>
                    </div>
                  </td>
                  <td>
                    <div className="bg-cardContainerGrey p-1 ml-2 rounded">
                      <span>{`${stat.value1}/${stat.value2}`}</span>
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default MarketStats;
