import React, { useEffect, useState } from "react";
import HeadingTitle from "../layout/HeadingTitle";
import { useNavigate } from "react-router-dom";
import { getTeamAvgSeasonStats } from "../../services/team-service";
import { TeamFlagBadge } from "../../shared/TeamFlagBadge";
import { getMatchFacts } from "../../services/match-service";

function DetailsContent({ homeTeamLastFive, guestTeamLastFive, matchDetails }) {
  const navigate = useNavigate();
  const [homeTeamStats, setHomeTeamStats] = useState({});
  const [guestTeamStats, setGuestTeamStats] = useState({});
  const [matchFacts, setMatchFacts] = useState({});

  useEffect(() => {
    // Assuming matchDetails contains team IDs and season ID
    const fetchStats = async () => {
      const homeStats = await getTeamAvgSeasonStats(
        matchDetails.matches[0].homeTeam.id,
        matchDetails.matches[0].season_id,
      );
      const guestStats = await getTeamAvgSeasonStats(
        matchDetails.matches[0].guestTeam.id,
        matchDetails.matches[0].season_id,
      );
      setHomeTeamStats(homeStats);
      setGuestTeamStats(guestStats);
    };

    const fetchMatchFacts = async () => {
      const bettingFacts = await getMatchFacts(matchDetails.matches[0].id);
      setMatchFacts(bettingFacts);
    };

    (async () => await fetchMatchFacts())();
    (async () => await fetchStats())();
  }, [matchDetails]);

  const statistics = [
    {
      type: "Zabijeni golovi",
      homeValue: homeTeamStats.scoredGoals,
      guestValue: guestTeamStats.scoredGoals,
    },
    {
      type: "Očekivani golovi",
      homeValue: homeTeamStats.expectedGoals,
      guestValue: guestTeamStats.expectedGoals,
    },
    {
      type: "Primljeni golovi",
      homeValue: homeTeamStats.concededGoals,
      guestValue: guestTeamStats.concededGoals,
    },
    {
      type: "Očekivani primljeni golovi",
      homeValue: homeTeamStats.expectedConcededGoals,
      guestValue: guestTeamStats.expectedConcededGoals,
    },
    {
      type: "Udarci",
      homeValue: homeTeamStats.totalShoots,
      guestValue: guestTeamStats.totalShoots,
    },
    {
      type: "Udarci u okvir",
      homeValue: homeTeamStats.shootsOnTarget,
      guestValue: guestTeamStats.shootsOnTarget,
    },
    {
      type: "Korneri",
      homeValue: homeTeamStats.cornerKicks,
      guestValue: guestTeamStats.cornerKicks,
    },
    {
      type: "Žuti karton",
      homeValue: homeTeamStats.yellowCards,
      guestValue: guestTeamStats.yellowCards,
    },
    {
      type: "Crveni karton",
      homeValue: homeTeamStats.redCards,
      guestValue: guestTeamStats.redCards,
    },
  ];

  const avgYellowCards =
    parseFloat(homeTeamStats.yellowCards) + parseFloat(guestTeamStats.yellowCards);
  const avgRedCards =
    parseFloat(homeTeamStats.redCards) + parseFloat(guestTeamStats.redCards);

  const mainOfficial = JSON.parse(
    matchDetails.matches[0].matchOfficialMain,
  ).filter((official) => official.type === "Main");
  const mainOfficialNames = mainOfficial
    .map((official) => `${official.firstName} ${official.lastName}`)
    .join(", ");
  const formatDate = (dateString, timeString) => {
    const options = { year: "numeric", month: "long", day: "numeric" };
    const date = new Date(`${dateString}T${timeString}`);
    const formattedDate = date.toLocaleDateString("hr-HR", options);
    const formattedTime = date.toLocaleTimeString("hr-HR", {
      hour: "2-digit",
      minute: "2-digit",
    });
    return `${formattedDate} ${formattedTime}`;
  };

  const gameInfo = [
    { type: "Lokacija", gamelocation: matchDetails.matches[0].location },
    {
      type: "Datum i vrijeme",
      gameDate: formatDate(
        matchDetails.matches[0].date,
        matchDetails.matches[0].time,
      ),
    },
    { type: "Sudac", gameJury: mainOfficialNames },
    { type: "Prosjek kartona", cardsAvg: { red: avgRedCards, yellow: avgYellowCards } },
  ];

  function getScoreColor(score, isHomeTeam) {
    const [homeScore, awayScore] = score.split(":").map(Number);
    if (isHomeTeam) {
      if (homeScore > awayScore) {
        return "bg-successGreen"; // Green for home team win
      } else if (homeScore < awayScore) {
        return "bg-redContainer"; // Red for home team loss
      } else {
        return "bg-textSecondary"; // Grey for draw
      }
    } else {
      if (homeScore > awayScore) {
        return "bg-redContainer"; // Red for away team loss
      } else if (homeScore < awayScore) {
        return "bg-successGreen"; // Green for away team win
      } else {
        return "bg-textSecondary"; // Grey for draw
      }
    }
  }

  function getUnderlineColor(score, isHomeTeam) {
    const [homeScore, awayScore] = score.split(":").map(Number);
    if (isHomeTeam) {
      if (homeScore > awayScore) {
        return "border-b-2 border-successGreen"; // Green underline for home team win
      } else if (homeScore < awayScore) {
        return "border-b-2 border-redContainer"; // Red underline for home team loss
      } else {
        return "border-b-2 border-textSecondary"; // Grey underline for draw
      }
    } else {
      if (homeScore > awayScore) {
        return "border-b-2 border-redContainer"; // Red underline for away team loss
      } else if (homeScore < awayScore) {
        return "border-b-2 border-successGreen"; // Green underline for away team win
      } else {
        return "border-b-2 border-textSecondary"; // Grey underline for draw
      }
    }
  }

  function navigateToMatch(matchId) {
    navigate("/match/" + matchId);
  }

  return (
    <div>
      {homeTeamLastFive.length > 0 && guestTeamLastFive.length > 0 && (
        <div>
          <HeadingTitle title={"Posljednjih 5 utakmica"} />
          {/* component for the last 5 games */}
          <div className="flex justify-center p-5 space-x-10">
            <div className="flex-1 flex flex-col items-center">
              <h2 className="font-medium text-center text-scoreText text-textSecondary pb-2">
                {matchDetails.matches[0].homeTeam.name}
              </h2>
              <table className="">
                <tbody className="">
                  {homeTeamLastFive.slice().reverse().map((homeTeamMatches, index) => (
                    <tr
                      key={index}
                      onClick={() => navigateToMatch(homeTeamMatches.id)}
                    >
                      <td className="px-2 py-1">
                        <TeamFlagBadge
                          flagUrl={homeTeamMatches.homeTeam.logoUrl}
                        />
                      </td>
                      <td className="pb-2 px-2 py-2 relative">
                        <div
                          className={`m-1 rounded h-5 w-8 flex justify-center items-center ${getScoreColor(
                            homeTeamMatches.homeTeam.scored +
                              ":" +
                              homeTeamMatches.guestTeam.scored,
                            homeTeamMatches.homeTeam.id ===
                              matchDetails.matches[0].homeTeam.id,
                          )}`}
                        >
                          <span className="text-white text-scoreText font-light">
                            {homeTeamMatches.homeTeam.scored +
                              ":" +
                              homeTeamMatches.guestTeam.scored}
                          </span>
                        </div>
                        {index === 0 && (
                          <div
                            className={`absolute bottom-[5px] left-[21px] w-3 rounded ${getUnderlineColor(
                              homeTeamMatches.homeTeam.scored +
                                ":" +
                                homeTeamMatches.guestTeam.scored,
                              homeTeamMatches.homeTeam.id ===
                                matchDetails.matches[0].homeTeam.id,
                            )}`}
                          ></div>
                        )}
                      </td>

                      <td className="px-2 py-1">
                        <TeamFlagBadge
                          flagUrl={homeTeamMatches.guestTeam.logoUrl}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className="flex-1 flex flex-col items-center">
              <h2 className="font-medium text-center text-scoreText text-textSecondary pb-2">
                {matchDetails.matches[0].guestTeam.name}
              </h2>
              <table>
                <tbody>
                  {guestTeamLastFive
                    .slice()
                    .reverse()
                    .map((guestTeamMatches, index) => (
                      <tr
                        key={index}
                        onClick={() => navigateToMatch(guestTeamMatches.id)}
                      >
                        <td className="px-2 py-1">
                          <TeamFlagBadge
                            flagUrl={guestTeamMatches.homeTeam.logoUrl}
                          />
                        </td>
                        <td className="pb-2 px-2 py-2 relative">
                          <div
                            className={`m-1 rounded h-5 w-8 flex justify-center items-center ${getScoreColor(
                              guestTeamMatches.homeTeam.scored +
                                ":" +
                                guestTeamMatches.guestTeam.scored,
                              guestTeamMatches.homeTeam.id ===
                                matchDetails.matches[0].guestTeam.id,
                            )}`}
                          >
                            <span className="text-white  text-scoreText font-light">
                              {guestTeamMatches.homeTeam.scored +
                                ":" +
                                guestTeamMatches.guestTeam.scored}
                            </span>
                          </div>
                          {index === 0 && (
                            <div
                              className={`absolute bottom-[5px] left-[21px] w-3 rounded ${getUnderlineColor(
                                guestTeamMatches.homeTeam.scored +
                                  ":" +
                                  guestTeamMatches.guestTeam.scored,
                                guestTeamMatches.homeTeam.id ===
                                  matchDetails.matches[0].guestTeam.id,
                              )}`}
                            ></div>
                          )}
                        </td>
                        <td className="px-2 py-1">
                          <TeamFlagBadge
                            flagUrl={guestTeamMatches.guestTeam.logoUrl}
                          />
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>{" "}
        </div>
      )}
      {matchFacts != null && matchFacts.length > 0 && (
        <div>
          <HeadingTitle title={`Statističke zanimljivosti`} />
          <div className="flex pl-3 pr-3 pt-2">
            <table className="w-full">
              <tbody>
                {matchFacts.map((matchFact, index) => (
                  <tr key={index}>
                    <td className="w-full pb-3">
                      <div className="relative flex items-center">
                        <div
                          className={`absolute left-0 top-1/2 transform -translate-y-1/2 h-[45px] w-[9px] ${
                            index % 2 === 0 ? "bg-green-500" : "bg-red-500"
                          } rounded-full`}
                        ></div>
                        <div className="pl-6 pr-3 py-1 bg-white text-scoreText font-extralight w-full text-left">
                          {matchFact.fact}
                        </div>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}
      {statistics != null && statistics.length > 0 && (
        <div>
          <HeadingTitle
            title={`Statistika ${matchDetails.matches[0].league.name}`}
          />
          <div className="flex pl-3 pr-3 pt-2 ">
            <table className="w-full">
              <tbody>
                {statistics.map((stat, index) => {
                  const homeStyle =
                    stat.homeValue > stat.guestValue
                      ? "bg-successGreen text-white text-scoreText rounded-md flex items-center justify-center p-1 font-extralight h-[24px] font-sm"
                      : "font-normal text-black text-scoreText items-center justify-center";
                  const guestStyle =
                    stat.guestValue > stat.homeValue
                      ? "bg-successGreen text-white text-scoreText rounded-md flex items-center justify-center p-1 font-extralight h-[24px]"
                      : "font-normal text-black text-scoreText items-center justify-center";

                  return (
                    <tr key={index} className="text-center">
                      <td className="w-[28px] h-[26px] pb-3">
                        <div className={homeStyle}>{stat.homeValue}</div>
                      </td>
                      <td className="pb-3 text-scoreText">{stat.type}</td>
                      <td className="w-[28px] h-[26px] pb-3">
                        <div className={guestStyle}>{stat.guestValue}</div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      )}
      {gameInfo != null && gameInfo.length > 0 && (
        <div>
          <HeadingTitle title={"Informacije o utakmici"} />
          <div className="flex">
            <table className="w-full">
              <tbody>
                {gameInfo.map((info, index) => (
                  <React.Fragment key={index}>
                    {/* Only render if at least one of gamelocation, gameDate, or gameJury is present */}
                    {(info.gamelocation ||
                      info.gameDate ||
                      info.gameJury ||
                      (info.cardsAvg &&
                        (info.cardsAvg.yellow > 0 ||
                          info.cardsAvg.red > 0))) && (
                      <tr>
                        <td>
                          <div className="flex-1 p-2 font-medium text-scoreText">
                            {info.type}
                          </div>
                        </td>
                        <td>
                          <div className="flex-1 text-right pr-2 font-normal text-scoreText">
                            <div className="flex items-center justify-end">
                              {info.gamelocation && (
                                <p className="">{info.gamelocation}</p>
                              )}
                              {info.gameDate && (
                                <p className="">{info.gameDate}</p>
                              )}
                              {info.gameJury && (
                                <p className="">{info.gameJury}</p>
                              )}
                              {info.cardsAvg && (
                                <React.Fragment>
                                    <React.Fragment>
                                      <img
                                        src="/img/svg/yellowCard.svg"
                                        alt="Yellow Card"
                                        className="h-6 mr-1 h-[15px]"
                                      />
                                      <span className="pr-4">
                                        {info.cardsAvg.yellow}
                                      </span>
                                    </React.Fragment>
                                    <React.Fragment>
                                      <img
                                        src="/img/svg/redCard.svg"
                                        alt="Red Card"
                                        className="h-6 mr-1 h-[15px]"
                                      />
                                      <span>{info.cardsAvg.red}</span>
                                    </React.Fragment>
                                </React.Fragment>
                              )}
                            </div>
                          </div>
                        </td>
                      </tr>
                    )}
                  </React.Fragment>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </div>
  );
}

export default DetailsContent;
