import React from "react";

const playerStatistics = {
  goals: 10,
  assists: 8,
  minutesPlayed: 900,
  gamesStarted: 10,
  totalGames: 10,
  shotsOnTarget: 20,
  tackles: 30,
  interceptions: 15,
  clearances: 40,
  shots: 50,
  dribblesCompleted: 25,
  chancesCreated: 10,
  yellowCards: 2,
  redCards: 0,
};

const statisticGroups = {
  "Statistitike natjecanja": [
    "goals",
    "assists",
    "minutesPlayed",
    "gamesStarted",
    "totalGames",
  ],
  Udarci: [
    "goals",
    "expectedGoals",
    "penGoals",
    "totalShots",
    "shotsOnTarget",
    "goalsSetplay",
  ],
  Dodavanja: [
    "totalPass",
    "expectedAssists",
    "totalAccuratePasses",
    "accuratePasses",
    "successfulFinalThirdPasses",
    "accurateLongBalls",
    "bigChanceCreated",
    "cornerTaken",
    "throws",
  ],
  Posjed: [
    "wonContest",
    "wonContestPercentage",
    "touches",
    "touchesFwd",
    "possessionTake",
    "foulsWon",
    "givenPens",
  ],
  Obrana: [
    "wonTackle",
    "wonTacklePercentage",
    "duelWon",
    "duelWonPercentage",
    "aerialWon",
    "aerialWonPercentage",
    "interception",
    "outfielderBlock",
    "returns",
    "successfulFinalThirdPossession",
    "totalContest",
  ],

  Disciplina: ["yellowCards", "redCards"],
};

const statTypeTranslations = {
  goals: "Golovi",
  assists: "Asistencije",
  minutesPlayed: "Minute",
  totalMinutes: "Minute",
  gamesStarted: "Započete utakmice",
  totalGames: "Utakmice",

  expectedGoals: "xG",
  penGoals: "Golovi iz penala",
  totalShots: "Udarci",
  shotsOnTarget: "Udarci na gol",
  goalsSetplay: " Golovi iz prekida",
  totalPass: "Asistencije",
  expectedAssists: "xA",
  totalAccuratePasses: "Uspješna dodavanja",
  accuratePasses: "Točnost dodavanja",
  successfulFinalThirdPasses: "Točna dodavanja u posljednjoj trećini",
  accurateLongBalls: "Točne duge lopte",
  bigChanceCreated: "Stvorene prlike",
  cornerTaken: "Uspješni korneri",
  throws: "Točnost ubačaja",

  wonContest: "Uspješni driblinzi",
  wonContestPercentage: "Uspješni driblinzi %",
  touches: "Dodiri",
  touchesFwd: "Dodiri u protivničkom polju",
  possessionTake: "Oduzimanje posjeda",
  foulsWon: "Dobiveni prekršaji",
  givenPens: "Dosuđeni jednaesterac",

  wonTackle: "Oduzete lopte",
  wonTacklePercentage: "Oduzete lopte %",
  duelWon: "Dobiveni dueli",
  duelWonPercentage: "Dobiveni duel %",
  aerialWon: "Dobiveni zračni dueli",
  aerialWonPercentage: "Dobiveni zračni dueli %",
  interception: "Presjecanja",
  outfielderBlock: "Blokovi",
  returns: "Vraćanja",
  successfulFinalThirdPossession: "Osvojeni posjedi u zadnjoj 1/3",
  totalContest: "Driblanje",

  tackles: "Startovi",
  interceptions: "Presijecanja",
  clearances: "Izbijanja",
  shots: "Šutevi",
  dribblesCompleted: "Uspješni driblinzi",
  chancesCreated: "Kreirane šanse",
  yellowCards: "Žuti kartoni",
  redCards: "Crveni kartoni",
};

const roundStat = (type, value) => {
  const parsedValue = parseFloat(value);
  if (isNaN(parsedValue)) {
    return "0";
  }
  return Math.round(parsedValue).toString();
};

const PlayerStatistics = () => {
  const renderStatRow = (type, value) => {
    const translatedType = statTypeTranslations[type] || type;
    const roundedValue = roundStat(type, value);

    return (
      <tr key={type} className="">
        <td className="px-2 py-2 text-scoreText font-fw500">
          {translatedType}
        </td>
        <td className="px-2 py-2 text-scoreText font-fw500 text-end">
          {roundedValue}
        </td>
      </tr>
    );
  };

  const renderStatGroup = (groupName, statTypes) => (
    <tbody key={groupName}>
      <tr>
        <th
          colSpan="2"
          className="text-left bg-cardContainerGrey p-2 font-fw500"
        >
          {groupName}
        </th>
      </tr>
      {statTypes.map((type) => renderStatRow(type, playerStatistics[type]))}
    </tbody>
  );

  return (
    <div>
      <table className="table-auto w-full my-4">
        {Object.entries(statisticGroups).map(([groupName, statTypes]) =>
          renderStatGroup(groupName, statTypes)
        )}
      </table>
    </div>
  );
};

export default PlayerStatistics;
